"use client";

import React, { createContext, useState, useContext, Suspense } from 'react';

type ItemContextType = {
  addItemOpen: boolean;
  setAddItemOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemDetailsOpen: boolean;
  setItemDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settingsOpen: boolean;
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSettingsOption: number;
  setSelectedSettingsOption: React.Dispatch<React.SetStateAction<number>>;
  refreshInventory: () => void;
  setRefreshInventory: React.Dispatch<React.SetStateAction<() => void>>;
  pricingOpen: boolean;
  setPricingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentMode: string;
  setCurrentMode: React.Dispatch<React.SetStateAction<string>>;
  sellRazeMobileOpen: boolean;
  setSellRazeMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inventoryOption: string;
  setInventoryOption: React.Dispatch<React.SetStateAction<string>>;
}

const ItemContext = createContext<ItemContextType | undefined>(undefined);

const ItemProviderContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [addItemOpen, setAddItemOpen] = useState<boolean>(false);
  const [itemDetailsOpen, setItemDetailsOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [selectedSettingsOption, setSelectedSettingsOption] = useState<number>(10);
  const [refreshInventory, setRefreshInventory] = useState(() => () => {});
  const [pricingOpen, setPricingOpen] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<string>("default");
  const [sellRazeMobileOpen, setSellRazeMobileOpen] = useState<boolean>(false);
  const [inventoryOption, setInventoryOption] = useState<string>("all");

  return (
    <ItemContext.Provider 
      value={{ 
        addItemOpen, 
        setAddItemOpen, 
        itemDetailsOpen, 
        setItemDetailsOpen, 
        settingsOpen,
        setSettingsOpen,
        selectedSettingsOption,
        setSelectedSettingsOption,
        refreshInventory,
        setRefreshInventory,
        pricingOpen,
        setPricingOpen,
        currentMode,
        setCurrentMode,
        sellRazeMobileOpen,
        setSellRazeMobileOpen,
        inventoryOption,
        setInventoryOption,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};


export const ItemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <ItemProviderContent>{children}</ItemProviderContent>
  </Suspense>
);

export const useItemContext = () => {
  const context = useContext(ItemContext);
  if (context === undefined) {
    throw new Error('addItemOpen must be used within an AddItemProvider');
  }
  return context;
};
